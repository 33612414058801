import { AxiosError } from 'axios'
import { enterApiFactory, EnterError } from '@design-system/api/apiFactory'
import { parseUnprotectedAuthProcess } from '@design-system/utils/authentication'
import router from 'next/router'
import { Page } from '@utils/urls'
import { signOut } from '@design-system/utils/firebaseAuthentication'
import { datadogRum } from '@datadog/browser-rum'

export const createEnterApi = (nextReqCookies?: Partial<{ [p: string]: string }>, publicUrl?: string) =>
    enterApiFactory({
        baseUrl: publicUrl || (process.env.NEXT_PUBLIC_SONIC_API_URL as string),
        authCookieName: process.env.NEXT_PUBLIC_AUTH_FIREBASE_COOKIE_NAME as string,
        requestMiddleware: {
            onRejected: async (err: AxiosError<EnterError>) => {
                datadogRum.addError(err)
                return Promise.reject(err)
            }
        },
        responseMiddleware: {
            onRejected: async (err: AxiosError<EnterError>) => {
                datadogRum.addError(err)
                if (err.response?.status === 401) {
                    if (window) {
                        try {
                            await signOut()
                        } catch (err) {
                            console.error(err)
                        }

                        const currentUrlAuthProcess = parseUnprotectedAuthProcess(router.query.authProcess)

                        if (!currentUrlAuthProcess) {
                            window.location.href = `${window.location.origin}${Page.LOGIN}`
                        }
                    }
                }

                return Promise.reject(err)
            },
        },
    })(nextReqCookies)
